import MainLayout from "../layouts/MainLayout";
import { Link, useParams } from "react-router-dom";
import css from './SoloEdit.module.css'
import { useEffect, useState } from "react";
import { editTeamEvents, getRewards, getTeamEvent } from "../api";

export default function TeamEventsEdit() {
    let [name, setName] = useState('')
    let [description, setDescription] = useState('')
    let [link, setLink] = useState('')
    let [image, setImage] = useState('')
    let [date, setDate] = useState('')
    let [goal, setGoal] = useState('none')
    let [type, setType] = useState('none')
    let [dType, setDType] = useState('none')
    let [rewardType, setRewardType] = useState('none')
    let [reward, setReward] = useState('')
    let [maxTeams, setMaxTeams] = useState('')
    let [teamPoints, setTeamPoints] = useState(0)
    let [leaderPoints, setLeaderPoints] = useState(0)
    let [minTeamPlayers, setMinTeamPlayers] = useState(0)
    let {id} = useParams()

    let [rewardsRaw, setRewardRaw] = useState([])

    function editEvent() {

        editTeamEvents({
            id,
            name,
            description,
            link,
            image,
            date,
            goal,
            type,
            maxTeams,
            rewardType,
            reward,
            dType,
            teamPoints,
            leaderPoints,
            minTeamPlayers
        })
        setTimeout(() => {
            window.location.href = '/team-events'
        }, 500)
    }

    useEffect(() => {
        getTeamEvent(id).then(res => {
            setName(res.name)
            setDescription(res.description)
            setLink(res.link)
            setImage(res.image)
            setDate(res.date)
            setMaxTeams(res.maxTeams)
            setGoal(res.goal)
            setType(res.type)
            setDType(res.dType)
            setTeamPoints(res.teamPoints)
            setLeaderPoints(res.leaderPoints)
            setMinTeamPlayers(res.minTeamPlayers)
        })

        getRewards().then(res => {
            setRewardRaw(res)
        })
    }, [])

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if(!file) return;

        // Initialize FileReader
        const reader = new FileReader();

        // Set up FileReader onload callback
        reader.onload = () => {
            // Read the file as a Data URL (Base64 string representation)
            const base64Result = reader.result;
            setImage(base64Result)
        };

        reader.readAsDataURL(file);
    };

    return (
        <MainLayout title={'Team Events Edit'}>
            <div className={css.header}>
                <Link to={'/team-events'}>Back</Link>
                <h1>Edit event</h1>
            </div>
            <div className={css.inputs}>
            <div className={css.inputBlock}>
                    <h1>Name</h1>
                    <input value={name} onChange={(e) => {
                        setName(e.target.value)
                    }} type="text" />
                </div>

                <div className={css.inputBlock}>
                    <h1>Description</h1>
                    <textarea value={description} onChange={(e) => {
                        setDescription(e.target.value)
                    }}/>
                </div>

                <div className={css.inputBlock}>
                    <h1>Link</h1>
                    <input value={link} onChange={(e) => {
                        setLink(e.target.value)
                    }} type="text" />
                </div>

                <div className={css.inputBlock}>
                    <h1>Max Teams</h1>
                    <input value={maxTeams} onChange={(e) => {
                        setMaxTeams(e.target.value)
                    }} type="text" />
                </div>

                <div className={css.inputBlock}>
                    <h1>Min Team Players</h1>
                    <input onChange={(e) => {
                        setMinTeamPlayers(e.target.value)
                    }} type="text" />
                </div>

                <div className={css.inputBlock}>
                    <h1>Type</h1>
                    <select value={type} onChange={(e) => {setType(e.target.value)}}>
                        <option value={'none'}>Нету</option>
                        <option value={'pvp'}>PvP</option>
                        <option value={'pve'}>PvE</option>
                    </select>
                </div>

                <div className={css.inputBlock}>
                    <h1>Departament</h1>
                    <select value={dType} onChange={(e) => {setDType(e.target.value)}}>
                        <option value="none">Нету</option>
                        <option value="srh">Звездно-Ресурсный Холдинг</option>
                        <option value="ebc">Инженерно-Строительный Корпус</option>
                        <option value="csld">ЧВК</option>
                        <option value="asld">Воздушно-Космическая ЧВК</option>
                        <option value="sei">Научно-исследовательский Институт</option>
                    </select>
                </div>

                <div className={css.inputBlock}>
                    <h1>Goal</h1>
                    <select value={goal} onChange={(e) => {setGoal(e.target.value)}}>
                        <option value={'none'}>Нету</option>
                        <option value={'defend'}>Охрана</option>
                        <option value={'attack'}>Нападание</option>
                        <option value={'building'}>Стройка</option>
                        <option value={'explore'}>Исследование</option>
                        <option value={'rp'}>RP</option>
                        <option value={'earning'}>Доход</option>
                        <option value={'gather'}>Сбор</option>
                    </select>
                </div>

                <div className={css.inputBlock}>
                    <h1>Reward Type</h1>
                    <select value={rewardType} onChange={(e) => {setRewardType(e.target.value)}}>
                        <option value={'none'}>Нету</option>
                        <option value={'money'}>Деньги</option>
                        <option value={'item'}>Медаль</option>
                    </select>
                </div>

                <div className={css.inputBlock}>
                    <h1>Reward</h1>
                    {rewardType == 'none' && <p>select type</p>}
                    {rewardType == 'money' && <input type="text" value={reward} onChange={(e) => {setReward(e.target.value)}}/>}
                    {rewardType == 'item' && <select onChange={(e) => {setReward(e.target.value)}}>
                        <option value="none">None</option>
                            {rewardsRaw.map(item => {
                                return <option value={item.id}>{item.name}</option>
                            })}
                        </select>}
                </div>

                <div className={css.inputBlock}>
                    <h1>Image</h1>
                    <input onChange={(e) => {
                        handleFileChange(e)
                    }} type="file" />
                    <img src={image} alt="" />
                </div>


                <div className={css.inputBlock}>
                    <h1>Team Points</h1>
                    <input value={teamPoints} onChange={(e) => {
                        setTeamPoints(e.target.value)
                    }} type="number" />
                </div>

                <div className={css.inputBlock}>
                    <h1>Leader Points</h1>
                    <input value={leaderPoints} onChange={(e) => {
                        setLeaderPoints(e.target.value)
                    }} type="number" />
                </div>

                <div className={css.inputBlock}>
                    <h1>Date</h1>
                    <input value={date} onChange={(e) => {
                        setDate(e.target.value)
                    }} type="datetime-local" />
                </div>
            </div>
            <div className={css.buttons}>
                <button onClick={() => {editEvent()}}>Edit</button>
            </div>
        </MainLayout>
    )
}