// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PlayerList_players__rgayu {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.PlayerList_player__4tM8y {
    display: flex;
    padding: 5px;
    border: 1px solid white;
    flex-direction: row;
    justify-content: space-between;
}

.PlayerList_player__4tM8y h1 {
    font-size: 20px;
    width: 15%;
    height: 50px;
    overflow: auto;
}

.PlayerList_header__U\\+nHR {
    height: 50px;
}

.PlayerList_playerManagment__jFOnW {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.PlayerList_playerManagment__jFOnW button {
    background-color: rgb(163, 47, 47);
    border: none;
    color: white;
    padding: 15px;
    cursor: pointer;
}

.PlayerList_playerManagment__jFOnW a {
    background-color: rgb(163, 47, 47);
    border: none;
    color: white;
    padding: 15px;
    cursor: pointer;
}

#PlayerList_edit__U-vr6 {

    background-color: rgb(163, 86, 47);
}

#PlayerList_parseShip__nKZFR {
    background-color: rgb(26, 115, 35);
    border: none;
    color: white;
    padding: 15px 20px;
    margin-bottom: 20px;
    cursor: pointer;
    margin-right: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/PlayerList.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;AACA;IACI,aAAa;IACb,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,UAAU;IACV,YAAY;IACZ,cAAc;AAClB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,QAAQ;AACZ;;AAEA;IACI,kCAAkC;IAClC,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,kCAAkC;IAClC,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,eAAe;AACnB;;AAEA;;IAEI,kCAAkC;AACtC;;AAEA;IACI,kCAAkC;IAClC,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".players {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n.player {\n    display: flex;\n    padding: 5px;\n    border: 1px solid white;\n    flex-direction: row;\n    justify-content: space-between;\n}\n\n.player h1 {\n    font-size: 20px;\n    width: 15%;\n    height: 50px;\n    overflow: auto;\n}\n\n.header {\n    height: 50px;\n}\n\n.playerManagment {\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    gap: 5px;\n}\n\n.playerManagment button {\n    background-color: rgb(163, 47, 47);\n    border: none;\n    color: white;\n    padding: 15px;\n    cursor: pointer;\n}\n\n.playerManagment a {\n    background-color: rgb(163, 47, 47);\n    border: none;\n    color: white;\n    padding: 15px;\n    cursor: pointer;\n}\n\n#edit {\n\n    background-color: rgb(163, 86, 47);\n}\n\n#parseShip {\n    background-color: rgb(26, 115, 35);\n    border: none;\n    color: white;\n    padding: 15px 20px;\n    margin-bottom: 20px;\n    cursor: pointer;\n    margin-right: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"players": `PlayerList_players__rgayu`,
	"player": `PlayerList_player__4tM8y`,
	"header": `PlayerList_header__U+nHR`,
	"playerManagment": `PlayerList_playerManagment__jFOnW`,
	"edit": `PlayerList_edit__U-vr6`,
	"parseShip": `PlayerList_parseShip__nKZFR`
};
export default ___CSS_LOADER_EXPORT___;
