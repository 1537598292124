// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SoloEvents_header__cl1pa a {
    border-radius: 0;
}

.SoloEvents_events__grKsX {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.SoloEvents_event__XpCJm {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid white;
}

.SoloEvents_event__XpCJm h2 {
    font-size: 17px;
    width: 200px;
    height: 50px;
    overflow-y: auto;
}

.SoloEvents_event__XpCJm a, .SoloEvents_event__XpCJm button {
    padding: 10px 25px;
    border-radius: 5px;
    background: none;
    color: white;
    border: 1px solid white;
    cursor: pointer;
    font-size: 17px;
    width: 140px;
}

.SoloEvents_event__XpCJm .SoloEvents_buttons__UvGHK {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.SoloEvents_event__XpCJm button {
    background-color: rgb(181, 51, 51);
    border: 1px solid rgb(181, 51, 51);
    padding: auto !important;
}

#SoloEvents_approve__ql5jN {
    background-color: rgb(51, 181, 97);
    border: 1px solid rgb(51, 181, 97);
}

#SoloEvents_save__P\\+gi4 {
    background-color: rgb(181, 51, 51);
    border: 1px solid rgb(181, 51, 51);
    color: white;
    padding: 20px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/SoloEvents.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,uBAAuB;IACvB,eAAe;IACf,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,kCAAkC;IAClC,kCAAkC;IAClC,wBAAwB;AAC5B;;AAEA;IACI,kCAAkC;IAClC,kCAAkC;AACtC;;AAEA;IACI,kCAAkC;IAClC,kCAAkC;IAClC,YAAY;IACZ,aAAa;IACb,eAAe;AACnB","sourcesContent":[".header a {\n    border-radius: 0;\n}\n\n.events {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n\n.event {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n    border: 1px solid white;\n}\n\n.event h2 {\n    font-size: 17px;\n    width: 200px;\n    height: 50px;\n    overflow-y: auto;\n}\n\n.event a, .event button {\n    padding: 10px 25px;\n    border-radius: 5px;\n    background: none;\n    color: white;\n    border: 1px solid white;\n    cursor: pointer;\n    font-size: 17px;\n    width: 140px;\n}\n\n.event .buttons {\n    display: flex;\n    flex-direction: row;\n    gap: 20px;\n}\n\n.event button {\n    background-color: rgb(181, 51, 51);\n    border: 1px solid rgb(181, 51, 51);\n    padding: auto !important;\n}\n\n#approve {\n    background-color: rgb(51, 181, 97);\n    border: 1px solid rgb(51, 181, 97);\n}\n\n#save {\n    background-color: rgb(181, 51, 51);\n    border: 1px solid rgb(181, 51, 51);\n    color: white;\n    padding: 20px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `SoloEvents_header__cl1pa`,
	"events": `SoloEvents_events__grKsX`,
	"event": `SoloEvents_event__XpCJm`,
	"buttons": `SoloEvents_buttons__UvGHK`,
	"approve": `SoloEvents_approve__ql5jN`,
	"save": `SoloEvents_save__P+gi4`
};
export default ___CSS_LOADER_EXPORT___;
