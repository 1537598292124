import MainLayout from "../layouts/MainLayout";
import { Link, Navigate, useParams } from "react-router-dom";
import css from './SoloNew.module.css'
import { useEffect, useState } from "react";
import {getReward, updateReward } from '../api'

export default function RewardEdit() {
    let [name, setName] = useState('')
    let [category, setCategory] = useState('none')
    let [subcategory, setSubCategory] = useState('none')
    let [image, setImage] = useState('')
    let [description, setDescription] = useState('')
    let [weight, setWeight] = useState('')
    let {id} = useParams()

    function saveReward() {
        updateReward({
            name,
            category,
            subcategory,
            description,
            weight,
            image
        })

        setTimeout(() => {
            window.location.href = '/rewards'
        }, 5000)
    }

    useEffect(() => {
        getReward(id).then(res => {
            setName(res.name)
            setCategory(res.category)
            setSubCategory(res.subcategory)
            setImage(res.image)
            setDescription(res.description)
            setWeight(res.weight)
        })
    }, [])

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if(!file) return;

        // Initialize FileReader
        const reader = new FileReader();

        // Set up FileReader onload callback
        reader.onload = () => {
            // Read the file as a Data URL (Base64 string representation)
            const base64Result = reader.result;
            setImage(base64Result)
        };

        reader.readAsDataURL(file);
    };

    return (
        <MainLayout title={'New Rewards'}>
            <div className={css.header}>
                <Link to={'/rewards'}>Back</Link>
                <h1>Edit Reward</h1>
            </div>

            <div className={css.inputs}>
                <div className={css.inputBlock}>
                    <h1>Name</h1>
                    <input onChange={(e) => {
                        setName(e.target.value)
                    }} value={name} type="text" />
                </div>

                <div className={css.inputBlock}>
                    <h1>Description</h1>
                    <textarea value={description} onChange={(e) => {
                        setDescription(e.target.value)
                    }}/>
                </div>

                <div className={css.inputBlock}>
                    <h1>Image</h1>
                    <input onChange={(e) => {
                        handleFileChange(e)
                    }} type="file" />
                    <img src={image} alt="" />
                </div>

                <div className={css.inputBlock}>
                    <h1>Category</h1>
                    <select onChange={(e) => {
                        setCategory(e.target.value)
                    }}>
                        <option value="none">Нету</option>
                        <option value="srh">Звездно-Ресурсный Холдинг</option>
                        <option value="ebc">Инженерно-Строительный Корпус</option>
                        <option value="csld">ЧВК</option>
                        <option value="asld">Воздушно-Космическая ЧВК</option>
                        <option value="sei">Научно-исследовательский Институт</option>
                    </select>
                </div>

                <div className={css.inputBlock}>
                    <h1>Sub category</h1>
                    <select onChange={(e) => {
                        setSubCategory(e.target.value)
                    }}>
                        <option value="none">Нету</option>
                        <option value="min">Майнер</option>
                        <option value="raz">Разборщик</option>
                        <option value="trd">Торговец</option>
                        <option value="een">Инженер</option>
                        <option value="bui">Прораб</option>
                        <option value="ofi">Офицер</option>
                        <option value="pil">Пилот</option>
                        <option value="ava">Авантюрист</option>
                        <option value="sci">Ученый</option>
                        <option value="arh">Археолог</option>
                        <option value="hck">Хакер</option>
                        <option value="sld">Солдат</option>
                        <option value="trl">Турельщик</option>
                        <option value="wrk">Разнорабочий</option>
                    </select>
                </div>

                <div className={css.inputBlock}>
                    <h1>Weight</h1>
                    <input value={weight} onChange={(e) => {
                        setWeight(e.target.value)
                    }} type="text" />
                </div>
            </div>
            <div className={css.buttons}>
                <button onClick={() => {saveReward()}}>Save</button>
            </div>
        </MainLayout>
    )
}