// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SoloEdit_header__HLGLJ a {
    border-radius: 0;
}

.SoloEdit_inputs__2Mubi {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.SoloEdit_inputBlock__Y9-oa {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.SoloEdit_inputBlock__Y9-oa img {
    width: 200px;
}

.SoloEdit_inputBlock__Y9-oa h1 {
    margin: 0;
}

.SoloEdit_inputBlock__Y9-oa input {
    padding: 5px 10px;
    border: 1px solid white;
    color: white;
    background: none;
    font-size: 17px;
}

.SoloEdit_inputBlock__Y9-oa textarea {
    padding: 5px 10px;
    border: 1px solid white;
    color: white;
    background: none;
    font-size: 17px;
}

.SoloEdit_buttons__Xwgcd {
    margin-top: 20px;
}

.SoloEdit_buttons__Xwgcd button {
    padding: 5px 25px;
    font-size: 20px;
    color: white;
    border: 1px solid rgb(18, 120, 18);
    background: rgb(18, 120, 18);
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/SoloEdit.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,iBAAiB;IACjB,uBAAuB;IACvB,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,uBAAuB;IACvB,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,YAAY;IACZ,kCAAkC;IAClC,4BAA4B;IAC5B,eAAe;AACnB","sourcesContent":[".header a {\n    border-radius: 0;\n}\n\n.inputs {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    flex-wrap: wrap;\n}\n\n.inputBlock {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n\n.inputBlock img {\n    width: 200px;\n}\n\n.inputBlock h1 {\n    margin: 0;\n}\n\n.inputBlock input {\n    padding: 5px 10px;\n    border: 1px solid white;\n    color: white;\n    background: none;\n    font-size: 17px;\n}\n\n.inputBlock textarea {\n    padding: 5px 10px;\n    border: 1px solid white;\n    color: white;\n    background: none;\n    font-size: 17px;\n}\n\n.buttons {\n    margin-top: 20px;\n}\n\n.buttons button {\n    padding: 5px 25px;\n    font-size: 20px;\n    color: white;\n    border: 1px solid rgb(18, 120, 18);\n    background: rgb(18, 120, 18);\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `SoloEdit_header__HLGLJ`,
	"inputs": `SoloEdit_inputs__2Mubi`,
	"inputBlock": `SoloEdit_inputBlock__Y9-oa`,
	"buttons": `SoloEdit_buttons__Xwgcd`
};
export default ___CSS_LOADER_EXPORT___;
