import MainLayout from "../layouts/MainLayout";
import { Link, useParams } from "react-router-dom";
import css from './SoloEdit.module.css'
import { useEffect, useState } from "react";
import { deleteEventPlayers, editSoloEvents, getRoles, getSoloEvent, getSoloEventPlayer, getSoloEventRoles, updateEventPlayers } from "../api";

export default function SoloEventPlayer() {
    let [playableRoles, setPlayableRoles] = useState([])
    let [mainRole, setMainRole] = useState("")
    let [recId, setRecId] = useState("")
    let {id} = useParams()
    let {userId} = useParams()

    useEffect(() => {
        getSoloEventPlayer(id, userId).then(res => {
            setMainRole(res.PlayableRole.id)
            setRecId(res.id)
        })
        getSoloEventRoles(id).then(res => {
            setPlayableRoles(res)
        })
    }, [])

    function editEvent() {
        updateEventPlayers(id, {
            players: [{
                id: recId,
                PlayableRoleId: mainRole
            }]
        }).then(res => {
            alert("success!")
        })
    }
    
    function deleteEvent() {
        deleteEventPlayers(id, {
            id: userId
        }).then(res => {
            window.location.href = `/solo-events/players/${id}`
        })
    }

    return (
        <MainLayout title={'Solo Events Edit'}>
            <div className={css.header}>
                <Link to={'/solo-events'}>Back</Link>
                <h1>Edit event</h1>
            </div>
            <div className={css.inputs}>
                <div className={css.inputBlock}>
                    <h1>Goal</h1>
                    <select value={mainRole} onChange={(e) => {setMainRole(e.target.value)}}>
                        {playableRoles.map(r => {
                            return <option value={r.id}>{r.Role.name}</option>
                        })}
                    </select>
                </div>
            </div>
            <div className={css.buttons}>
                <button onClick={() => {editEvent()}}>Edit</button>
            </div>
            <div className={css.buttons}>
                <button onClick={() => {deleteEvent()}}>Delete</button>
            </div>
        </MainLayout>
    )
}