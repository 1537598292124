import { Link } from "react-router-dom";
import css from './LoginPage.module.css'

// https://discord.com/oauth2/authorize?client_id=1246958281235103806&response_type=code&redirect_uri=https%3A%2F%2F9s90brbd-3000.euw.devtunnels.ms%2Fauthorized&scope=identify
// https://discord.com/oauth2/authorize?client_id=1246958281235103806&response_type=code&redirect_uri=http%3A%2F%2Flocalhost%3A3000%2Fauthorized&scope=identify
// https://discord.com/oauth2/authorize?client_id=1246958281235103806&response_type=code&redirect_uri=https%3A%2F%2Fgv2zxb4n-3000.euw.devtunnels.ms%2Fauthorized&scope=identify
// https://discord.com/oauth2/authorize?client_id=1246958281235103806&response_type=code&redirect_uri=https%3A%2F%2Fadmin.sunwaysystems.space%2Fauthorized&scope=identify

export default function LoggingPage() {
    return (
        <div className={css.wrapp}>
            <Link to={'https://discord.com/oauth2/authorize?client_id=1246958281235103806&response_type=code&redirect_uri=https%3A%2F%2Fadmin.sunwaysystems.space%2Fauthorized&scope=identify'}>Login</Link>
        </div>
    )
}