import MainLayout from "../layouts/MainLayout";
import { Link } from "react-router-dom";
import css from './SoloEvents.module.css'
import {deleteTeamEvent, getAwaitingExchanges, getTeamEventApplications, getTeamEvents, updateAwaitingExchanges, updateTeamEventApplications} from '../api'
import { useEffect, useState } from "react";

export default function AwaitingExchanges() {

    let [applications, setApplications] = useState([])

    useEffect(() => {
        getAwaitingExchanges().then(res => {
            setApplications(res)
        })
    }, [])

    async function handleApprove(id) {
        updateAwaitingExchanges({
            id,
            status: 'approved'
        }).then(() => {
            window.location.reload()
        })
    }

    async function handleReject(id) {
        updateAwaitingExchanges({
            id,
            status: 'rejected'
        }).then(() => {
            window.location.reload()
        })
    }

    return (
        <MainLayout title={'Awaiting Exchange Items'}>
            <div className={css.header}>
                <h1>Requests</h1>
            </div>
            <div className={css.events}>
                {applications.sort((a,b) => (a.id - b.id)).map(application => {
                    return <div className={css.event}>
                        <h2>Request ID: {application.id}</h2>
                        <h2>User Handle: {application.User.handle}</h2>
                        <h2>Item Name: {application.ExchangeItem.name}</h2>
                        <div className={css.buttons}>
                            <button id={css.approve} onClick={() => {handleApprove(application.id)}}>Approve</button>
                            <button onClick={() => {handleReject(application.id)}}>Reject</button>
                        </div>
                    </div>
                })}
            </div>
        </MainLayout>
    )
}