import MainLayout from "../layouts/MainLayout";
import { Link } from "react-router-dom";
import css from './SoloEvents.module.css'
import {deleteSoloEvent, finishSoloEvent, getSoloEvents} from '../api'
import { useEffect, useState } from "react";

export default function SoloEvents() {

    let [soloEvents, setSoloEvents] = useState([])

    function handleDelete(id) {
        deleteSoloEvent(id).then(() => {
            window.location.reload()
        })
    }

    function handleFinish(id) {
        finishSoloEvent(id).then(() => {
            window.location.reload()
        })
    }

    useEffect(() => {
        getSoloEvents().then(res => {
            console.log(res)
            setSoloEvents(res)
        })
    }, [])

    return (
        <MainLayout title={'Solo Events'}>
            <div className={css.header}>
                <Link to={'/solo-events/new'}>New Event</Link>
                <h1>Events</h1>
            </div>
            <div className={css.events}>
                {soloEvents.sort((a,b) => (a.id - b.id)).map(soloEvent => {
                    return <div className={css.event}>
                        <h2>{soloEvent.id}</h2>
                        <h2>{soloEvent.name}</h2>
                        <h2>{soloEvent.description}</h2>
                        <h2>Created: {soloEvent.created}</h2>
                        <h2>Start: {soloEvent.date}</h2>
                        <h2>{soloEvent.link}</h2>
                        <div className={css.buttons}>
                            <Link to={`/solo-events/players/${soloEvent.id}`}>Участники</Link>
                            <Link to={`/solo-events/edit/${soloEvent.id}`}>Edit</Link>
                            <button onClick={() => {handleDelete(soloEvent.id)}}>Delete</button>
                            {soloEvent.status != 'finished' ? <button onClick={() => {handleFinish(soloEvent.id)}}>Finish</button> : ''}
                        </div>
                    </div>
                })}
            </div>
        </MainLayout>
    )
}