import MainLayout from "../layouts/MainLayout";
import { Link } from "react-router-dom";
import css from './PlayerList.module.css'
import {deleteReward, getRewards} from '../api'
import { useEffect, useState } from "react";

let decrypt = {
    none: "Нету",
    srh: "Звездно-Ресурсный Холдинг",
    ebc: "Инженерно-Строительный Корпус",
    csld: "ЧВК",
    asld: "Воздушно-Космическая ЧВК",
    sei: "Научно-исследовательский Институт"
}

let decrypt2 = {
    none: "Нету",
    min: "Майнер",
    raz: "Разбойщик",
    trd: "Торговец",
    een: "Инженер",
    bui: "Прораб",
    pil: "Пилот",
    ofi: "Офицер",
    ava: "Авантюрист",
    sci: "Ученый",
    arh: "Археолог",
    hck: "Хакер",
    sld: "Солдат",
    trl: "Турельщик",
    wrk: "Разнорабочий",
}

export default function RewardsPage() {

    let [rewards, setRewards] = useState([])

    useEffect(() => {
        getRewards().then(res => {
            console.log(res)
            setRewards(res)
        })
    }, [])

    function handleDelete(id) {
        deleteReward(id).then(() => {
            window.location.reload()
        })
    }

    return (
        <MainLayout title={'Rewards'}>
            <div className={css.header}>
                <Link to={'/rewards/create'}>New</Link>
            </div>
            <div className={css.players}>
                <div className={css.player}>
                    <h1>ID</h1>
                    <h1>Name</h1>
                    <h1>Category</h1>
                    <h1>Subcategory</h1>
                    <h1>Weight</h1>
                </div>
                {rewards.map(reward => (
                    <div className={css.playerManagment}>
                        <div className={css.player}>
                            <h1>{reward.id}</h1>
                            <h1>{reward.name}</h1>
                            <h1>{decrypt[reward.category]}</h1>
                            <h1>{decrypt2[reward.subcategory]}</h1>
                            <h1>{reward.weight}</h1>
                        </div>
                        <button onClick={() => {handleDelete(reward.id)}}>Delete</button>
                        <button onClick={() => {window.location.href = `/rewards/edit/${reward.id}`}} id={css.edit}>Edit</button>
                    </div>
                ))}
            </div>
        </MainLayout>
    )
}