// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TransferPage_transferList__f1CPg {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.TransferPage_transferRequest__GLwkP {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin: 0;
    justify-content: space-between;
}

.TransferPage_transferRequest__GLwkP .TransferPage_buttons__P1j6p {
    width: 200px;
    display: flex;
    justify-content: space-between;
}

.TransferPage_transferRequest__GLwkP p {
    width: 200px;
}

.TransferPage_transferRequest__GLwkP button {
    padding: 10px 15px;
    cursor: pointer;
}

#TransferPage_reject__sEvq4 {
    border: none;
    background: red;
    color: white;
    font-weight: 900;
}

#TransferPage_approve__7MX9K {
    border: none;
    background: green;
    color: white;
    font-weight: 900;
}

.TransferPage_sortBlock__mb\\+NV button {
    padding: 10px;
    border: 1px solid white;
    background: none;
    color: white;
    width: 150px;
    cursor: pointer;
}

.TransferPage_sortBlock__mb\\+NV {
    display: flex;
    flex-direction: row;
    gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/TransferPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;IACX,SAAS;IACT,8BAA8B;AAClC;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb","sourcesContent":[".transferList {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    width: 100%;\n}\n\n.transferRequest {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    width: 100%;\n    margin: 0;\n    justify-content: space-between;\n}\n\n.transferRequest .buttons {\n    width: 200px;\n    display: flex;\n    justify-content: space-between;\n}\n\n.transferRequest p {\n    width: 200px;\n}\n\n.transferRequest button {\n    padding: 10px 15px;\n    cursor: pointer;\n}\n\n#reject {\n    border: none;\n    background: red;\n    color: white;\n    font-weight: 900;\n}\n\n#approve {\n    border: none;\n    background: green;\n    color: white;\n    font-weight: 900;\n}\n\n.sortBlock button {\n    padding: 10px;\n    border: 1px solid white;\n    background: none;\n    color: white;\n    width: 150px;\n    cursor: pointer;\n}\n\n.sortBlock {\n    display: flex;\n    flex-direction: row;\n    gap: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"transferList": `TransferPage_transferList__f1CPg`,
	"transferRequest": `TransferPage_transferRequest__GLwkP`,
	"buttons": `TransferPage_buttons__P1j6p`,
	"reject": `TransferPage_reject__sEvq4`,
	"approve": `TransferPage_approve__7MX9K`,
	"sortBlock": `TransferPage_sortBlock__mb+NV`
};
export default ___CSS_LOADER_EXPORT___;
