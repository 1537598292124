// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BankPage_header__YGEN3 a {
    border-radius: 0;
    width: 100px;
}

.BankPage_categories__WqE-b {
    gap: 10px;
    display: flex;
    flex-direction: row;
}

.BankPage_header__YGEN3 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.BankPage_header__YGEN3 h1 {
    font-size: 20px;
}

.BankPage_inputs__bIOEG {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.BankPage_inputBlock__\\+--Xb {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1 1;
}

.BankPage_inputBlock__\\+--Xb h1 {
    margin: 0;
}

.BankPage_inputBlock__\\+--Xb input {
    width: 400px;
    padding: 10px;
    color:  white;
    background: none;
    border: 1px solid white;
    border-radius: 10px;
}

.BankPage_inputBlock__\\+--Xb button {
    width: 200px;
    border: 1px solid white;
    background: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/pages/BankPage.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,SAAS;IACT,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,SAAO;AACX;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,uBAAuB;IACvB,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,eAAe;AACnB","sourcesContent":[".header a {\n    border-radius: 0;\n    width: 100px;\n}\n\n.categories {\n    gap: 10px;\n    display: flex;\n    flex-direction: row;\n}\n\n.header {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.header h1 {\n    font-size: 20px;\n}\n\n.inputs {\n    display: flex;\n    flex-direction: row;\n    margin-top: 20px;\n}\n\n.inputBlock {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    flex: 1;\n}\n\n.inputBlock h1 {\n    margin: 0;\n}\n\n.inputBlock input {\n    width: 400px;\n    padding: 10px;\n    color:  white;\n    background: none;\n    border: 1px solid white;\n    border-radius: 10px;\n}\n\n.inputBlock button {\n    width: 200px;\n    border: 1px solid white;\n    background: none;\n    color: white;\n    font-size: 20px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `BankPage_header__YGEN3`,
	"categories": `BankPage_categories__WqE-b`,
	"inputs": `BankPage_inputs__bIOEG`,
	"inputBlock": `BankPage_inputBlock__+--Xb`
};
export default ___CSS_LOADER_EXPORT___;
