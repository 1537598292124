import MainLayout from "../layouts/MainLayout";
import { Link, useParams } from "react-router-dom";
import css from './SoloEvents.module.css'
import {deleteSoloEvent, finishSoloEvent, getSoloEventPlayers, getSoloEvents, getTeamEventPlayers, getTeamEventTeams, updateTeamEventPlayers, updateTeamEventTeams} from '../api'
import { useEffect, useState } from "react";

export default function TeamEventTeams() {

    let [teams, setTeams] = useState([])
    let {id} = useParams()

    useEffect(() => {
        getTeamEventTeams(id).then(res => {
            setTeams(res)
        })
    }, [])


    function saveChanges(teamId) {
        updateTeamEventTeams(id, {
            id: teamId
        }).then(() => {
            window.location.reload()
        })
    }

    return (
        <MainLayout title={'Team Events'}>
            <div className={css.header}>
                <h1>Event Teams</h1>
            </div>
            <div className={css.events}>
                {teams.sort((a,b) => (a.id - b.id)).map(team => {
                    return <div className={css.event}>
                        <h1>{team.name}</h1>
                        <button onClick={() => {saveChanges(team.id)}}>Удалить команду с ивента</button>
                    </div>
                })}
            </div>
        </MainLayout>
    )
}