import { useEffect, useState } from "react";
import MainLayout from "../layouts/MainLayout";
import css from './PlayerList.module.css'
import { deletePlayer, deleteShip, getPlayers, getShips, getShipsStats, parseShips } from "../api";

export default function ShipPage() {

    let [ships, setShips] = useState([])

    useEffect(() => {
        getShips().then(res => {
            setShips(res)
        })
    }, [])

    function handleDelete(id) {
        deleteShip(id).then(() => {
            window.location.reload()
        })
    }

    function handleParse(id) {
        parseShips().then(() => {
            window.location.reload()
        })
    }

    const downloadFile = () => {

        getShipsStats().then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const a = document.createElement('a');
                a.href = url;
                a.download = 'export.txt';
                document.body.appendChild(a);
                a.click();
                a.remove();
            })
            .catch((error) => {
                console.error('Ошибка загрузки файла:', error);
            });
    };

    return (
        <MainLayout title={'Ship List'}>
            <button id={css.parseShip} onClick={() => {handleParse()}}>Parse Ships</button>
            <button id={css.parseShip} onClick={() => {downloadFile()}}>Export stats</button>
            <div className={css.players}>
                <div className={css.player}>
                    <h1>Id</h1>
                    <h1>Ship Name</h1>
                    <h1>Description</h1>
                    <h1>Image</h1>
                </div>
                {ships.map(ship => (
                    <div className={css.playerManagment}>
                        <div className={css.player}>
                            <h1>{ship.id}</h1>
                            <h1>{ship.name}</h1>
                            <h1>{ship.description}</h1>
                            <h1>{ship.image}</h1>
                        </div>
                        <button onClick={() => {handleDelete(ship.id)}}>Delete {ship.name}</button>
                    </div>
                ))}
            </div>
        </MainLayout>
    )
}