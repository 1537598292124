import { useEffect, useState } from "react";
import { getDebtLogs, getTransferRequests, updateTransferRequest } from "../api";
import MainLayout from "../layouts/MainLayout";
import css from './TransferPage.module.css'

export default function TransferPage(){

    let [transfers, setTransfers] = useState([])
    let [expenses, setExpenses] = useState([])
    let [sortState, setSortState] = useState('income')

    useEffect(() => {
        getTransferRequests().then(res => {
            setTransfers(res)
        })

        getDebtLogs().then(res => {
            setExpenses(res)
        })

    }, [])

    function handleApprove(id, approved) {
        updateTransferRequest({
            id,
            approved
        })

        window.location.reload()
    }

    return (
        <MainLayout title={'Money Transfer Page'}>
            <div className={css.sortBlock}>
                <button onClick={() => {setSortState('income')}}>Income</button>
                <button onClick={() => {setSortState('expenses')}}>Expenses</button>
            </div>
            <div className={css.transferList}>

                {sortState == 'income' ? transfers.map(transfer => {
                        return (<div className={css.transferRequest}>
                            <p>{transfer.handle}</p>
                            <p>{transfer.discordId}</p>
                            <p>{transfer.receiverHandle}</p>
                            <p>+{transfer.amount}</p>
                            <p>Status: {transfer.approved ? 'approved': (transfer.status == 'checked' ? 'rejected' : 'new')}</p>
                            <p>{transfer.time}</p>
                            <p>{transfer.note}</p>
                            <div className={css.buttons}>
                                <button disabled={transfer.status == 'checked'} onClick={() => {handleApprove(transfer.id, false)}} id={css.reject}>Reject</button>
                                <button disabled={transfer.status == 'checked'} onClick={() => {handleApprove(transfer.id, true)}} id={css.approve}>Approve</button>
                            </div>
                        </div>)
                }) : expenses.map(log => {
                    return (<div className={css.transferRequest}>
                        <p>Handle: {log.handle}</p>
                        <p>-{log.amount}</p>
                        <p>{log.time}</p>
                    </div>)
                })}
            </div>
        </MainLayout>
    )
}