// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainLayout_bodyApp__VGzI6 {
    display: flex;
    flex-direction: column;
    gap: 50px;
}

.MainLayout_header__VYqyc {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid white;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
}

.MainLayout_header__VYqyc a {
    border: 0;
    font-size: 20px;
    padding: 0 !important;
    width: auto;
}

.MainLayout_header__VYqyc h1 {
    font-size: 20px;
}

.MainLayout_container__s5oXx {
    padding: 0 20px;
}`, "",{"version":3,"sources":["webpack://./src/layouts/MainLayout.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,8BAA8B;IAC9B,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,SAAS;IACT,eAAe;IACf,qBAAqB;IACrB,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".bodyApp {\n    display: flex;\n    flex-direction: column;\n    gap: 50px;\n}\n\n.header {\n    display: flex;\n    justify-content: center;\n    border-bottom: 1px solid white;\n    align-items: center;\n    justify-content: space-between;\n    padding: 0 50px;\n}\n\n.header a {\n    border: 0;\n    font-size: 20px;\n    padding: 0 !important;\n    width: auto;\n}\n\n.header h1 {\n    font-size: 20px;\n}\n\n.container {\n    padding: 0 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bodyApp": `MainLayout_bodyApp__VGzI6`,
	"header": `MainLayout_header__VYqyc`,
	"container": `MainLayout_container__s5oXx`
};
export default ___CSS_LOADER_EXPORT___;
