import MainLayout from "../layouts/MainLayout";
import { Link, Navigate, useParams } from "react-router-dom";
import css from './SoloNew.module.css'
import { useEffect, useState } from "react";
import {createDepartment, createSoloEvents, getDepartment, updateDepartment} from '../api'

export default function DepartmentEdit() {
    let [name, setName] = useState('')
    let [type, setType] = useState('none')
    let {id} = useParams()
    useEffect(() => {
        getDepartment(id).then(res => {
            setName(res.name)
            setType(res.type)
        })
    }, [])

    function saveDeparment() {

        if(!name) {
            alert('Enter name please')
            return
        }
        if(type == 'none') {
            alert('Select type please')
            return
        }

        updateDepartment({
            name,
            type,
            id
        }).then((res) => {
            // window.location.href ="/solo-events"
            alert('success')
        })
    }

    return (
        <MainLayout title={'Department New'}>
            <div className={css.header}>
                <Link to={'/departments'}>Back</Link>
                <h1>Edit Department</h1>
            </div>

            <div className={css.inputs}>
                <div className={css.inputBlock}>
                    <h1>Name</h1>
                    <input value={name} onChange={(e) => {
                        setName(e.target.value)
                    }} type="text" />
                </div>


                <div className={css.inputBlock}>
                    <h1>Type</h1>
                    <select value={type} onChange={(e) => {setType(e.target.value)}}>
                        <option value="none">Нету</option>
                        <option value="srh">Звездно-Ресурсный Холдинг</option>
                        <option value="ebc">Инженерно-Строительный Корпус</option>
                        <option value="csld">ЧВК</option>
                        <option value="asld">Воздушно-Космическая ЧВК</option>
                        <option value="sei">Научно-исследовательский Институт</option>
                    </select>
                </div>
            </div>
            <div className={css.buttons}>
                <button onClick={() => {saveDeparment()}}>Save</button>
            </div>
        </MainLayout>
    )
}