// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DebtPage_debtList__R-w94 {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.DebtPage_debtRequest__sEHLH {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-around;
    margin: 0;
}

.DebtPage_debtRequest__sEHLH .DebtPage_buttons__mHfu5 {
    width: 200px;
    display: flex;
    justify-content: space-between;
}

.DebtPage_debtRequest__sEHLH button {
    padding: 10px 15px;
    cursor: pointer;
}

#DebtPage_reject__4\\+m1Q {
    border: none;
    background: red;
    color: white;
    font-weight: 900;
}

#DebtPage_approve__\\+BEo2 {
    border: none;
    background: green;
    color: white;
    font-weight: 900;
}

.DebtPage_sortBlock__e6\\+Db button {
    padding: 10px;
    border: 1px solid white;
    background: none;
    color: white;
    width: 150px;
    cursor: pointer;
}

.DebtPage_sortBlock__e6\\+Db {
    display: flex;
    flex-direction: row;
    gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/DebtPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,WAAW;AACf;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;IACX,6BAA6B;IAC7B,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb","sourcesContent":[".debtList {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    width: 100%;\n}\n\n.debtRequest {\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    width: 100%;\n    justify-content: space-around;\n    margin: 0;\n}\n\n.debtRequest .buttons {\n    width: 200px;\n    display: flex;\n    justify-content: space-between;\n}\n\n.debtRequest button {\n    padding: 10px 15px;\n    cursor: pointer;\n}\n\n#reject {\n    border: none;\n    background: red;\n    color: white;\n    font-weight: 900;\n}\n\n#approve {\n    border: none;\n    background: green;\n    color: white;\n    font-weight: 900;\n}\n\n.sortBlock button {\n    padding: 10px;\n    border: 1px solid white;\n    background: none;\n    color: white;\n    width: 150px;\n    cursor: pointer;\n}\n\n.sortBlock {\n    display: flex;\n    flex-direction: row;\n    gap: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"debtList": `DebtPage_debtList__R-w94`,
	"debtRequest": `DebtPage_debtRequest__sEHLH`,
	"buttons": `DebtPage_buttons__mHfu5`,
	"reject": `DebtPage_reject__4+m1Q`,
	"approve": `DebtPage_approve__+BEo2`,
	"sortBlock": `DebtPage_sortBlock__e6+Db`
};
export default ___CSS_LOADER_EXPORT___;
