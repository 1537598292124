import MainLayout from "../layouts/MainLayout";
import { Link } from "react-router-dom";
import css from './SoloEvents.module.css'
import {deleteTeamEvent, getTeamEvents} from '../api'
import { useEffect, useState } from "react";

export default function TeamEvents() {

    let [teamEvents, setTeamEvents] = useState([])

    function handleDelete(id) {
        deleteTeamEvent(id).then(() => {
            window.location.reload()
        })
    }

    useEffect(() => {
        getTeamEvents().then(res => {
            console.log(res)
            setTeamEvents(res)
        })
    }, [])

    return (
        <MainLayout title={'Team Events'}>
            <div className={css.header}>
                <Link to={'/team-events/new'}>New Event</Link>
                <h1>Events</h1>
            </div>
            <div className={css.events}>
                {teamEvents.sort((a,b) => (a.id - b.id)).map(teamEvent => {
                    return <div className={css.event}>
                        <h2>{teamEvent.id}</h2>
                        <h2>{teamEvent.name}</h2>
                        <h2>{teamEvent.description}</h2>
                        <h2>Start: {teamEvent.date}</h2>
                        <h2>Approved: {teamEvent.approved}</h2>
                        <h2>Rejected: {teamEvent.rejected}</h2>
                        <h2>{teamEvent.type}</h2>
                        <div className={css.buttons}>
                            <Link to={`/team-events/players/${teamEvent.id}`}>Участники</Link>
                            <Link to={`/team-events/teams/${teamEvent.id}`}>Команды</Link>
                            <Link to={`/team-events/edit/${teamEvent.id}`}>Edit</Link>
                            <Link to={`/team-events/finish/${teamEvent.id}`}>Finish</Link>
                            <button onClick={() => {handleDelete(teamEvent.id)}}>Delete</button>
                        </div>
                    </div>
                })}
            </div>
        </MainLayout>
    )
}