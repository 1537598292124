import axios from "axios";

// const BASE_URL = 'http://localhost:3001/api'
const BASE_URL = 'https://api.sunwaysystems.space/api'

export async function getAllSettings() {
    let data = await axios.get(`${BASE_URL}/settings`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return data.data
}

export async function getAllTexts() {
    let data = await axios.get(`${BASE_URL}/settings/texts`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return data.data
}

export async function updateSettings(body) {
    let request = await axios.post(`${BASE_URL}/settings/update`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return 200
}


export async function updateTexts(body) {
    let request = await axios.post(`${BASE_URL}/settings/edit/text`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return 200
}


export async function getDebtRequests() {
    let data = await axios.get(`${BASE_URL}/debt`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return data.data
}

export async function updateDebtRequest(body) {
    let request = await axios.post(`${BASE_URL}/debt/update`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return 200
}

export async function getTransferRequests() {
    let data = await axios.get(`${BASE_URL}/transfers`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return data.data
}


export async function updateTransferRequest(body) {
    let request = await axios.post(`${BASE_URL}/transfers/update`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return 200
}

export async function getPlayers() {
    let request = await axios.get(`${BASE_URL}/players`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function deletePlayer(id) {
    let request = await axios.post(`${BASE_URL}/players/delete/${id}`, {}, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function getSoloEvents() {
    let request = await axios.get(`${BASE_URL}/solo-events`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export async function getSoloEventPlayers(id) {
    let request = await axios.get(`${BASE_URL}/solo-events/get/${id}/players`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}


export async function getSoloEventRoles(id) {
    let request = await axios.get(`${BASE_URL}/solo-events/get/${id}/playableroles`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export async function getSoloEventPlayer(id, userId) {
    let request = await axios.get(`${BASE_URL}/solo-events/get/${id}/players/${userId}`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export async function updateEventPlayers(id, body) {
    let request = await axios.post(`${BASE_URL}/solo-events/get/${id}/players/update`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export async function deleteEventPlayers(id, body) {
    let request = await axios.post(`${BASE_URL}/solo-events/get/${id}/players/delete`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export async function getSoloEvent(id) {
    let request = await axios.get(`${BASE_URL}/solo-events/get/${id}`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export async function createSoloEvents(body) {
    let request = await axios.post(`${BASE_URL}/solo-events/new`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}


export async function editSoloEvents(body) {
    let request = await axios.post(`${BASE_URL}/solo-events/edit`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export async function deleteSoloEvent(id) {
    let request = await axios.post(`${BASE_URL}/solo-events/delete/${id}`, {}, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function finishSoloEvent(id) {
    let request = await axios.post(`${BASE_URL}/solo-events/end/${id}`, {}, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function authToken(code) {
    let request = await axios.post(`${BASE_URL}/auth/token?code=${code}`)

    return request.data
}


export async function getCapital() {
    let request = await axios.get(`${BASE_URL}/bank`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function saveCapital(body) {
    let request = await axios.post(`${BASE_URL}/bank/update`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function getDebtors() {
    let request = await axios.get(`${BASE_URL}/debt/debtors`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function getDebtLogs() {
    let request = await axios.get(`${BASE_URL}/transfers/expenses`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function removeDebtor(id) {
    let request = await axios.post(`${BASE_URL}/debt/debtors/delete/${id}`, {}, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function getTeamEvents() {
    let request = await axios.get(`${BASE_URL}/team-events`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export async function getTeamEvent(id) {
    let request = await axios.get(`${BASE_URL}/team-events/get/${id}`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export async function getTeamEventPlayers(id) {
    let request = await axios.get(`${BASE_URL}/team-events/get/${id}/players`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export async function updateTeamEventPlayers(id, body) {
    let request = await axios.post(`${BASE_URL}/team-events/get/${id}/players/update`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export async function getTeamEventTeams(id) {
    let request = await axios.get(`${BASE_URL}/team-events/get/${id}/teams`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export async function updateTeamEventTeams(id, body) {
    let request = await axios.post(`${BASE_URL}/team-events/get/${id}/teams/delete`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export async function createTeamEvents(body) {
    let request = await axios.post(`${BASE_URL}/team-events/new`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}


export async function finishTeamEvent(body) {
    let request = await axios.post(`${BASE_URL}/team-events/finish`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export async function editTeamEvents(body) {
    let request = await axios.post(`${BASE_URL}/team-events/edit`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export async function deleteTeamEvent(id) {
    let request = await axios.post(`${BASE_URL}/team-events/delete/${id}`, {}, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function getTeams() {
    let request = await axios.get(`${BASE_URL}/team`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export async function getTeamsByEvent(id) {
    let request = await axios.get(`${BASE_URL}/team/event/${id}`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export async function getTeam(id) {
    let request = await axios.get(`${BASE_URL}/team/${id}`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export async function deleteTeam(id) {
    let request = await axios.post(`${BASE_URL}/team/delete/${id}`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export async function sendCommands(type) {
    let request = await axios.post(`${BASE_URL}/settings/send`, {type}, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export async function getRoles() {
    let request = await axios.get(`${BASE_URL}/roles`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}


export async function getTitles() {
    let request = await axios.post(`${BASE_URL}/departments/positions`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export async function getRole(id) {
    let request = await axios.get(`${BASE_URL}/roles/${id}`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export function createRole(body) {
    let request = axios.post(`${BASE_URL}/roles/create`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}


export async function updateRole(body) {
    let request = await axios.post(`${BASE_URL}/roles/update`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export async function deleteRole(body) {
    let request = await axios.post(`${BASE_URL}/roles/delete`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}
export async function getRewards() {
    let request = await axios.get(`${BASE_URL}/rewards`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export async function getReward(id) {
    let request = await axios.get(`${BASE_URL}/rewards/${id}`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export function createReward(body) {
    let request = axios.post(`${BASE_URL}/rewards/create`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}


export async function updateReward(body) {
    let request = await axios.post(`${BASE_URL}/rewards/update`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export async function deleteReward(id) {
    let request = await axios.post(`${BASE_URL}/rewards/delete/${id}`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })
    
    return request.data
}

export async function getRate() {
    let request = await axios.get(`${BASE_URL}/exchange/money`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}
export async function updateRate(body) {
    let request = await axios.post(`${BASE_URL}/exchange/money/update`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function getExchangeRoles() {
    let request = await axios.get(`${BASE_URL}/exchange/roles`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}
export async function getExchangeRole(id) {
    let request = await axios.get(`${BASE_URL}/exchange/roles/${id}`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}


export async function getExchangeTitles() {
    let request = await axios.get(`${BASE_URL}/exchange/titles`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function getExchangeTitle(id) {
    let request = await axios.get(`${BASE_URL}/exchange/titles/${id}`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function getExchangeItems() {
    let request = await axios.get(`${BASE_URL}/exchange/items`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function getExchangeItem(id) {
    let request = await axios.get(`${BASE_URL}/exchange/items/${id}`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function createExchangeItem(body) {
    let request = await axios.post(`${BASE_URL}/exchange/items/create`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function createExchangeRole(body) {
    let request = await axios.post(`${BASE_URL}/exchange/roles/create`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function createExchangeTitle(body) {
    let request = await axios.post(`${BASE_URL}/exchange/titles/create`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}


export async function updateExchangeItem(body) {
    let request = await axios.post(`${BASE_URL}/exchange/items/update`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function updateExchangeRole(body) {
    let request = await axios.post(`${BASE_URL}/exchange/roles/update`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function updateExchangeTitle(body) {
    let request = await axios.post(`${BASE_URL}/exchange/titles/update`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function deleteExchangeItem(body) {
    let request = await axios.post(`${BASE_URL}/exchange/items/delete`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function deleteExchangeRole(body) {
    let request = await axios.post(`${BASE_URL}/exchange/roles/delete`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function deleteExchangeTitle(body) {
    let request = await axios.post(`${BASE_URL}/exchange/titles/delete`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function geRecruitmentsTeams() {
    let request = await axios.get(`${BASE_URL}/recruitments`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function getRecruitment(id) {
    let request = await axios.get(`${BASE_URL}/recruitments/${id}`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function updateRecruitment(id, body) {
    let request = await axios.post(`${BASE_URL}/recruitments/${id}`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function getShips() {
    let request = await axios.get(`${BASE_URL}/hangar`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function getShipsStats() {
    let request = await axios.get(`${BASE_URL}/hangar/download`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        },
        responseType: "blob"
    })

    return request
}

export async function parseShips() {
    let request = await axios.post(`${BASE_URL}/hangar/parse`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function deleteShip(id) {
    let request = await axios.post(`${BASE_URL}/hangar/delete/${id}`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function getTeamEventApplications() {
    let request = await axios.get(`${BASE_URL}/teApplications`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function updateTeamEventApplications(body) {
    let request = await axios.post(`${BASE_URL}/teApplications/edit`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function getAwaitingExchanges() {
    let request = await axios.get(`${BASE_URL}/awaitingexchanges`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function updateAwaitingExchanges(body) {
    let request = await axios.post(`${BASE_URL}/awaitingexchanges/edit`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function getDepartments() {
    let request = await axios.get(`${BASE_URL}/departments`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function getDepartment(id) {
    let request = await axios.get(`${BASE_URL}/departments/${id}`, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function createDepartment(body) {
    let request = await axios.post(`${BASE_URL}/departments/create`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}

export async function updateDepartment(body) {
    let request = await axios.post(`${BASE_URL}/departments/update`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}


export async function deleteDepartment(body) {
    let request = await axios.post(`${BASE_URL}/departments/delete`, body, {
        headers: {
            "Authorization": `Bearer ${localStorage.getItem('token')}`
        }
    })

    return request.data
}