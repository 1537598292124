// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SettingsPage_inputs__-v5uL {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.SettingsPage_inputBlock__hh97b {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1 1;
}

.SettingsPage_inputBlock__hh97b h1 {
    margin: 0;
}

.SettingsPage_inputBlock__hh97b input {
    width: 400px;
    padding: 10px;
    color:  white;
    background: none;
    border: 1px solid white;
    border-radius: 10px;
}

.SettingsPage_inputBlock__hh97b button {
    width: 200px;
    border: 1px solid white;
    background: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
}

.SettingsPage_buttons__cL811 {
    display: flex;
    flex-direction: row;
    gap: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/SettingsPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,SAAO;AACX;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,aAAa;IACb,gBAAgB;IAChB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,uBAAuB;IACvB,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ","sourcesContent":[".inputs {\n    display: flex;\n    flex-direction: row;\n    flex-wrap: wrap;\n}\n\n.inputBlock {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    flex: 1;\n}\n\n.inputBlock h1 {\n    margin: 0;\n}\n\n.inputBlock input {\n    width: 400px;\n    padding: 10px;\n    color:  white;\n    background: none;\n    border: 1px solid white;\n    border-radius: 10px;\n}\n\n.inputBlock button {\n    width: 200px;\n    border: 1px solid white;\n    background: none;\n    color: white;\n    font-size: 20px;\n    cursor: pointer;\n}\n\n.buttons {\n    display: flex;\n    flex-direction: row;\n    gap: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputs": `SettingsPage_inputs__-v5uL`,
	"inputBlock": `SettingsPage_inputBlock__hh97b`,
	"buttons": `SettingsPage_buttons__cL811`
};
export default ___CSS_LOADER_EXPORT___;
