import { Link, useSearchParams } from "react-router-dom";
import css from './LoginPage.module.css'
import { useEffect } from "react";
import { authToken } from "../api";

export default function AuthorizedPage() {
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if(searchParams.get('code') != null) {
            authToken(searchParams.get('code')).then(res => {
                if(res.authorized == true) {
                    localStorage.setItem('token', res.token)
                }
                
                window.location.href = '/'

                return
            })
        }

        return
    }, [])

    return (
        <div className={css.wrapp}>
            you will be redirected soon
        </div>
    )
}