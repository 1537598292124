import MainLayout from "../layouts/MainLayout";
import { Link, Navigate } from "react-router-dom";
import css from './SoloNew.module.css'
import { useEffect, useState } from "react";
import {createRole, createSoloEvents} from '../api'

export default function RoleNew() {
    let [name, setName] = useState('')
    let [category, setCategory] = useState('none')
    let [isWelcome, setIsWelcome] = useState(false)

    function saveRole() {

        if(!name) {
            alert('Enter name please')
            return
        }
        if(!category) {
            alert('Enter category please')
            return
        }

        createRole({
            name,
            category,
            isWelcome
        })

        setTimeout(() => {
            window.location.href = "/roles"
        }, 500)
    }

    return (
        <MainLayout title={'Roles'}>
            <div className={css.header}>
                <Link to={'/roles'}>Back</Link>
                <h1>New Role</h1>
            </div>

            <div className={css.inputs}>
                <div className={css.inputBlock}>
                    <h1>Name</h1>
                    <input onChange={(e) => {
                        setName(e.target.value)
                    }} type="text" />
                </div>

                <div className={css.inputBlock}>
                    <h1>Category</h1>
                    <select onChange={(e) => {
                        setCategory(e.target.value)
                    }}>
                        <option value="none">Нету</option>
                        <option value="srh">Звездно-Ресурсный Холдинг</option>
                        <option value="ebc">Инженерно-Строительный Корпус</option>
                        <option value="csld">ЧВК</option>
                        <option value="asld">Воздушно-Космическая ЧВК</option>
                        <option value="sei">Научно-исследовательский Институт</option>
                    </select>
                </div>
                
                <div className={css.inputBlock}>
                    <h1>Is welcome</h1>
                    <button onClick={() => {setIsWelcome(!isWelcome)}}>{isWelcome ? "True": "False"}</button>
                </div>
            </div>
            <div className={css.buttons}>
                <button onClick={() => {saveRole()}}>Create</button>
            </div>
        </MainLayout>
    )
}