import MainLayout from "../layouts/MainLayout";
import { Link, Navigate, useParams } from "react-router-dom";
import css from './SoloNew.module.css'
import { useEffect, useState } from "react";
import {createTeamEvents, finishTeamEvent, getRewards, getTeamsByEvent} from '../api'

export default function TeamEventEnd() {
    let [teams, setTeams] = useState([])
    let [winnerTeam, setWinner] = useState('none')
    let {id} = useParams()

    useEffect(() => {
        getTeamsByEvent(id).then(res => {
            setTeams(res)
        })
    }, [])

    function saveEvent() {
        if(winnerTeam == 'none') return alert('Выберите победителя')
        finishTeamEvent({
            id,
            winnerTeam
        }).then(() => {
            alert('Success')
            // window.location.href = "/team-events"
        })
    }

    
    return (
        <MainLayout title={'Team Events Finish'}>
            <div className={css.header}>
                <Link to={'/team-events'}>Back</Link>
                <h1>Finish Team Event</h1>
            </div>

            <div className={css.inputs}>
                <div className={css.inputBlock}>
                    <h1>Choose Team</h1>
                    <select onChange={(e) => {setWinner(e.target.value)}} name="" id="">
                        <option value="none">None</option>
                        {teams.map(team => {
                            return <option value={team.id}>{team.name}</option>
                        })}
                    </select>
                </div>
            </div>
            <div className={css.buttons}>
                <button onClick={() => {saveEvent()}}>Finish</button>
            </div>
        </MainLayout>
    )
}