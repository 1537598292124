// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SoloNew_header__pTo1c a {
    border-radius: 0;
}

.SoloNew_inputs__H9gjv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.SoloNew_inputBlock__62vAO {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.SoloNew_inputBlock__62vAO h1 {
    margin: 0;
}

.SoloNew_inputBlock__62vAO input {
    padding: 5px 10px;
    border: 1px solid white;
    color: white;
    background: none;
    font-size: 17px;
}

.SoloNew_inputBlock__62vAO img {
    width: 250px;
}

.SoloNew_inputBlock__62vAO textarea {
    padding: 5px 10px;
    border: 1px solid white;
    color: white;
    background: none;
    font-size: 17px;
}

.SoloNew_buttons__CkY\\+q {
    margin-top: 20px;
}

.SoloNew_buttons__CkY\\+q button {
    padding: 5px 25px;
    font-size: 20px;
    color: white;
    border: 1px solid rgb(18, 120, 18);
    background: rgb(18, 120, 18);
}

.SoloNew_roles__A90ik {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.SoloNew_roles__A90ik button {
    border: 1px solid white;
    background: none;
    color: white;
    padding: 10px;
}

.SoloNew_roles__A90ik input {
    background: none;
    border: 1px solid white;
    padding: 5px;
    color: white;
}

.SoloNew_role__1NQaM {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/pages/SoloNew.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,iBAAiB;IACjB,uBAAuB;IACvB,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,uBAAuB;IACvB,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,YAAY;IACZ,kCAAkC;IAClC,4BAA4B;AAChC;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;;AAEA;IACI,uBAAuB;IACvB,gBAAgB;IAChB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,gBAAgB;IAChB,uBAAuB;IACvB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC","sourcesContent":[".header a {\n    border-radius: 0;\n}\n\n.inputs {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    flex-wrap: wrap;\n}\n\n.inputBlock {\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n}\n\n.inputBlock h1 {\n    margin: 0;\n}\n\n.inputBlock input {\n    padding: 5px 10px;\n    border: 1px solid white;\n    color: white;\n    background: none;\n    font-size: 17px;\n}\n\n.inputBlock img {\n    width: 250px;\n}\n\n.inputBlock textarea {\n    padding: 5px 10px;\n    border: 1px solid white;\n    color: white;\n    background: none;\n    font-size: 17px;\n}\n\n.buttons {\n    margin-top: 20px;\n}\n\n.buttons button {\n    padding: 5px 25px;\n    font-size: 20px;\n    color: white;\n    border: 1px solid rgb(18, 120, 18);\n    background: rgb(18, 120, 18);\n}\n\n.roles {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n\n.roles button {\n    border: 1px solid white;\n    background: none;\n    color: white;\n    padding: 10px;\n}\n\n.roles input {\n    background: none;\n    border: 1px solid white;\n    padding: 5px;\n    color: white;\n}\n\n.role {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `SoloNew_header__pTo1c`,
	"inputs": `SoloNew_inputs__H9gjv`,
	"inputBlock": `SoloNew_inputBlock__62vAO`,
	"buttons": `SoloNew_buttons__CkY+q`,
	"roles": `SoloNew_roles__A90ik`,
	"role": `SoloNew_role__1NQaM`
};
export default ___CSS_LOADER_EXPORT___;
